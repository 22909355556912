const theme = {
  colors: {
    black: "#040307",
    orange: "#D8552A",
    tan: "#D7B9A3",
    white: "#FCFEFF",
    blue: "#1F43BF",
    gray:"#D5DCE5",
  },
};

export default theme;